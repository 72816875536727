/* Main Archive Section Styling */
.archive_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../../../../assets/themes/USC-1/dark/site-images/gradientbg.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  padding-top: 130px; /* Adjust based on your header height */
  padding-bottom: 20px;
  box-sizing: border-box;
}
/* Title Styling */
.page-title {
  text-align: center;
  color: #FFFFFF; /* Adjust the color to fit your design */
  font-size: 2.5rem; /* Example size, adjust as needed */
  margin-top: 20px;
  margin-bottom: 10px; /* Spacing between title and description */
  font-weight: bold; /* Optional: if you want the title to be bold */
}

/* Description Styling */
.page-description {
  text-align: center;
  color: #CCCCCC; /* Lighter color for the description, adjust as needed */
  font-size: 1.25rem; /* Adjust based on your preference */
  margin-bottom: 20px; /* Spacing between description and the content below */
  max-width: 80%; /* Limit the width of the description text */
  margin-left: auto; /* These two lines center the description block */
  margin-right: auto;
}


/* Grid Container for Video Thumbnails */
.grid-container {
  display: grid;
  width: auto; /* Ensures the grid container takes up the full width */
  max-width: 1280px; /* Maximum width of the grid container */
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Responsive grid */
  grid-gap: 20px;
  padding: 20px;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  aspect-ratio: 16 / 9;
  padding-bottom: 60px; /* Adjust this value as needed to fit the overlay content */
}



/* Styling for Video Element inside Thumbnail */
.thumbnail video {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9; /* Maintain a 16:9 aspect ratio */
  object-fit: cover; /* Ensure cover aspect to avoid stretching */
}

.thumbnail-title {
  position: absolute;
  bottom: 60px; /* Match this value to the padding-bottom of the thumbnail */
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  text-align: left;
  padding: 10px;
  font-size: 14px;
}


/* Video Overlay Fullscreen Styling */
.video-overlay {
  display: none; /* Initially hidden; toggle this in your JS logic */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1050; /* Ensure it's above everything else */
  justify-content: center;
  align-items: center;
}

.video-card {
  cursor: pointer;
  background: #000;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  margin: 5px;
}

.video-thumbnail {
  width: 100%;
  aspect-ratio: 16 / 9;
  object-fit: cover;
}

.video-info {
  background-color: #fff; /* or any color you want for the background */
  padding: 10px;
  text-align: left;
}

/* You may remove .thumbnail-title styles if they are no longer needed */


/* Responsive Adjustments */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .page-title {
    font-size: 28px;
  }
}

@media (max-width: 480px) {
  .archive_section {
    padding-top: 50px;
  }

  .page-title {
    font-size: 24px;
  }

  .thumbnail-title {
    font-size: 12px; /* Adjust font size for readability on small devices */
  }
}

@media (max-width: 480px) {
  .archive_section {
    padding-top: 50px; /* Reduced top padding */
  }

  .page-title,
  .page-description {
    font-size: 18px; /* Smaller font size for smaller screens */
    margin: 10px 0; /* Adjust margins for smaller screens */
  }

  .grid-container {
    grid-template-columns: 1fr; /* One column on small screens */
    padding: 10px; /* Adjust padding for smaller screens */
    grid-gap: 10px; /* Less gap for smaller screens */
  }

  .thumbnail,
  .video-card {
    padding-bottom: 20px; /* Adjust if your overlay content needs more space */
  }

  .thumbnail-title {
    font-size: 16px; /* Reconsider if this size is appropriate for small devices */
  }
}
