@import "../../../../theme/index.scss";

// registration form start
.get_set_regiter {
  background: #1d0545;
  padding: 80px 0px;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  align-items: center;
  h3 {
    color: white;
    font-size: 48px;
    font-weight: 700;
    font-family: "Montserrat";
    margin: 0;
    padding: 0;
    font-family: $fontFamily;
    span {
      font-weight: 700;
      color: #ffffff;
      font-size: 68px;
      // font-family: 'Montserrat';
    }
  }
  .btn_reg {
    // button{

    // }
    font-size: 30px;
    font-weight: 400;
    height: 100px;
    line-height: 1.7;
    border-radius: 50px;
    display: inline-flex;
    height: 70px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    // color: white;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding: 30px;
    transition: color 300ms ease-in-out;
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
    &:hover {
      color: white;
      box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
      background-color: rgba(104, 85, 224, 1);
    }
  }
  @media (max-width:1024px) {
    h3{
      font-size: 42px;
    }
  }
  @media (max-width:786px) {
    h3{
      font-size: 28px;
      span{
        font-size: 38px;
      }
    }
    
    .btn_reg{
     font-size: 18px;
    }
    
  }
  @media (max-width:425px) {
    padding: 30px 4px;
    h3{
      font-size: 12px;
      span{
        font-size: 18px;
      }
    }
    
    .btn_reg{
     font-size: 14px;
     height: 50px;
     padding: 8px;
    }
    
  }
}
// .modal_registration{
.react-responsive-modal-modal {
  width: 60%;
background-color: transparent !important;
box-shadow: none !important;
  .form_input {
    width: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 12px;
    margin-top: 18px;
    label {
      display: block;
      color: white;
      font-size: 18px;
      padding: 10px 0px;
    }
    input,
    textarea {
      width: 100%;
      padding: 14px;
      border-radius: 28px;
      &:focus {
        outline: none;
        border: none;
      }
    }
    button {
      cursor: pointer;
      border: 0;
      border-radius: 28px;
      font-weight: 600;
      width: 100%;
      font-family: $fontFamily;
      padding: 14px 18px;
      box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
      transition: 0.4s;
      letter-spacing: -0.03px;
      font-size: 18px;
      color: rgb(104, 85, 224);
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(104, 85, 224, 1);
      &:hover {
        color: white;
        box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
        background-color: rgba(104, 85, 224, 1);
      }
    }

  }
  .react-responsive-modal-closeButton {
    top: 50px;
     right: 40px;
    // // svg{
    // //   color: red !important;
    // // }
  }
  @media (max-width:768px) {
    width: 80%;
    
  }
  @media (max-width:425px) {
    width: 100%;
    margin: 0rem !important;
    .form_input{
      margin-top: 55px;
    }
    .react-responsive-modal-closeButton{
      top: 82px;
      right: 32px;
    }
  }
  
}
// registration form end

// }

.contactus_section {
  background-image: url("../../../../assets/themes//USC-1/dark/site-images/gradientbg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 65px;
  // border-radius: 20px 20px 20px 20px;
  .nested_section {
    width: 80%;
    margin: 0 auto;
    .content_contact {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      .contact_data {
        h1 {
          color: #ffffff;
          font-size: 48px;
          font-family: $fontFamily;
          font-weight: 700;
          font-style: normal;
          text-decoration: none;
          line-height: 1.2em;
          letter-spacing: 0px;
        }
        p {
          color: #ffffff;
          font-family: $fontFamily;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          text-decoration: none;
          line-height: 1.5em;
          letter-spacing: 0px;
          padding: 10px 0px;
        }
        button, a {
          cursor: pointer;
          border: 0;
          border-radius: 28px;
          font-weight: 600;
          // margin: 0 10px;
          width: 160px;
          font-family: $fontFamily;
          padding: 14px 18px;
          box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
          transition: 0.4s;
          letter-spacing: -0.03px;
          font-size: 18px;
          color: rgb(104, 85, 224);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(104, 85, 224, 1);
          &:hover {
            color: white;
            box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
            background-color: rgba(104, 85, 224, 1);
          }
        }
        @media (max-width: 1024px) {
          h1 {
            font-size: 32px;
          }
        }
        @media (max-width: 425px) {
          h1 {
            font-size: 24px;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column-reverse;
      }
    }

    .form {
      .heading_form {
        color: #ffffff;
        font-size: 48px;
        font-family: $fontFamily;
        font-weight: 700;
        font-style: normal;
        text-decoration: none;
        line-height: 1.2em;
        letter-spacing: 0px;
        border-bottom: 1px solid #8706e1;
      }
      .nested_form{
        @media (max-width:768px) {
           flex-direction: column;  
        }
      }
     
      ul {
        width: 100%;
        color: #ffffff;
        font-family: $fontFamily;

        li {
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 4px 0px;
          font-size: 18px;
        }
      }
      .form_input {
        width: 100%;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 12px;
        margin-top: 18px;
        label {
          display: block;
          color: white;
          font-size: 18px;
          padding: 10px 0px;
        }
        input,
        textarea {
          width: 100%;
          padding: 14px;
          border-radius: 28px;
          &:focus {
            outline: none;
            border: none;
          }
        }
        button {
          cursor: pointer;
          border: 0;
          border-radius: 28px;
          font-weight: 600;
          width: 100%;
          font-family: $fontFamily;
          padding: 14px 18px;
          box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
          transition: 0.4s;
          letter-spacing: -0.03px;
          font-size: 18px;
          color: rgb(104, 85, 224);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(104, 85, 224, 1);
          &:hover {
            color: white;
            box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
            background-color: rgba(104, 85, 224, 1);
          }
        }
      }
    }

    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    
  }
  @media (max-width: 768px) {
    padding: 45px;
  }
  @media (max-width: 425px) {
    padding: 25px;
  }
}
