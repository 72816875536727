@import "../../../theme/index.scss";

.navbar_Section {
  width: 100%;
  height: 10%;
  font-family: $fontFamily;
  position: fixed;
  top: 0px;
  z-index: 9999;
  background-color: #1d0545;
  background-image: url("../../../assets/themes/USC-1/dark/site-images/bgheader.png");
  // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  .prices {
    display: flex;
    align-items: center;
    gap: 10px; // Adjust gap as needed
    color: rgb(255, 255, 255);
    
  }
.nested_navbarSection {
  width: 80%;
  height: 80px;
  margin: 0 auto;
.navbar_contentSection {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
.logo {
    img {
      width: 150px;
      padding-top: 60px;
        }
      }
      .links {
        a {
          // font-family: "Nanum Gothic", Sans-serif;
          font-family: $fontFamily;

          font-size: 16px;
          font-weight: 300;
          text-transform: uppercase;
          letter-spacing: -0.03px;
          color: white;
        }
        .dropdown {
          display: inline-block;
          position: relative;

          button,p{
            font-size: 16px;
            font-weight: 300;
            text-transform: uppercase;
            letter-spacing: -0.03px;
            color: white;
            cursor: pointer;
          }
        }
        .dropdown-content {
          display: none;
          position: absolute;
          width: 100%;
          overflow: auto;
          // top: 20px;
          width: 200px;
          // box-shadow: 0px 10px 10px 0px white;
        }
        .dropdown:hover .dropdown-content {
          display: block;
          background: #1d0545;
          border-radius: 2px;

        }
        .dropdown-content a {
          display: block;
          color: white;
          padding: 5px;
          text-decoration: none;
          padding: 12px 10px;
          font-size: 16px;
          color: rgb(104, 85, 224);
          background-color: rgba(255, 255, 255, 1);
          // border: 1px solid rgba(104, 85, 224, 1);
          &:hover {
            color: white;
            box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
            background-color: rgba(104, 85, 224, 1);
          }
        }
        // .dropdown-content a:hover {
        //   color: #FFFFFF;
        //   background-color: #00A4BD;
        // }


      }
      .btn_header {
        display: flex;
        align-items: center;
        button {
          cursor: pointer;
          border: 0;
          border-radius: 28px;
          font-weight: 600;
          margin: 0 10px;
          width: 160px;
          padding: 10px 0;
          box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
          transition: 0.4s;
          letter-spacing: -0.03px;
          font-size: 18px;
          color: rgb(104, 85, 224);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(104, 85, 224, 1);
          &:hover {
            color: white;
            box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
            background-color: rgba(104, 85, 224, 1);
          }
          @media (max-width:425px) {
            width: 120px;
          }
        }
      }
      @media (max-width:425px) {
        width: 100%;
      }
    }
    @media (max-width:768px) {
      width: 90%;
      .links{
        display: none;
      }
    }
    @media (max-width:425px) {
      // width: 95%;
      .links{
        display: none;
      }
    }
  }
  .btn-primary {
    // border-color: #afe334 !important;
    // background-color: #afe334 !important;
    // color: #000;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
          transition: 0.4s;
          letter-spacing: -0.03px;
          font-size: 18px;
          color: rgb(104, 85, 224);
          background-color: rgba(255, 255, 255, 1);
          border: 1px solid rgba(104, 85, 224, 1);
          &:hover {
            color: white;
            box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
            background-color: rgba(104, 85, 224, 1);
          }
          svg{
            font-size: 1.5rem;
          }
  }
  .showButton{
    display: none;
  }
  @media (max-width:900px) {
    .showButton{
      display: inline-block;
    } 
  }
}
