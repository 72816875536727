@import ".../../../../../theme/index.scss";

.refund_policy {
  width: 100%;
  .nested_refund {
    width: 100%;
    background-image: url("../../../assets/themes/USC-1/dark/site-images/bgsecond-1.png");
    background-size: cover;
    height: auto;
    .child_refund {
      width: 80%;
      margin: 0 auto;
      margin-top: 70px;

      .logo_refund {
        width: 100%;
        display: flex;
        justify-content: center;
        img {
          width: 350px;
        }
        @media (max-width: 425px) {
          img {
            width: 220px;
          }
        }
      }
      .refund_content{
        font-family: $fontFamily;
        .points {
          color: #fff;
          h1 {
            font-size: 38px;
            font-weight: 600;
          }

          p {
            font-size: 16px;
            letter-spacing: 0.6px;
          }
          h4 {
            font-size: 28px;
            font-weight: 600;
            padding: 18px 0px;
          }
          h5 {
            font-size: 28px;
            font-weight: 600;
          }
        }
        @media (max-width:425px) {
          .points {
              h1{
                  font-size: 32px;
              }
              h5{
                  font-size: 26px;

              }
              h4{
             font-size: 22px;
              }
          }
      }
      }
      @media (max-width:768px) {
        width: 90%;
      }
    }
  }
}
