@import "../../../../theme/index.scss";

.members_landing-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 50px 20px; // Adjust padding as needed
  // Add other styles like background color, etc., as per your design
}

/* Styling for .icon-buttons to ensure vertical alignment on small screens with added padding */
.icon-buttons {
  display: flex;
  flex-direction: column; /* Vertical layout for mobile screens */
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 10px; /* Padding around icons on small screens */
  width: 1200px;
}

/* Styling for .icon-placeholder for a consistent icon size across different screen sizes */
.icon-placeholder {
  width: 80px; /* Initial size to keep icons readable and large enough */
  height: 80px;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  color: #000;

  &:hover {
    box-shadow: 0 0px 100px rgba(104, 85, 224, 0.6);
    transition: box-shadow 0.5s ease-in-out;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    display: block;
    margin: 0;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
  }
}

/* Media Queries */

@media (min-width: 481px) {
  .icon-buttons {
    flex-direction: column; /* Keep the vertical layout for small screens */
    justify-content: center;
    align-items: center;
  }
  
  .icon-placeholder {
   
    width: 200px; /* Slightly larger icons for small screens */
    height: 200px;
  }
}


/* Adjust for tablet-sized screens: Horizontal layout with larger icons */
@media (min-width: 768px) and (max-width: 1024px) {
  .icon-buttons {
    flex-direction: row; /* Switch to horizontal layout for tablets */
    flex-wrap: wrap; /* Allow wrapping as needed */
    justify-content: center; /* Center icons horizontally */
    padding: 10px; /* Adjust padding for spacing around icons */
  }
  
  .icon-placeholder {
    width: 150px; /* Slightly larger icons for tablets, adjust as needed */
    height: 150px;
  }
}

/* Responsive adjustments for larger screens */
@media (min-width: 1025px) {
  .icon-buttons {
    flex-direction: row; /* Horizontal layout for larger screens */
    flex-wrap: wrap; /* Allow wrapping if more items are added */
    justify-content: center; /* Distribute icons evenly in the available space */
    align-items: center; /* Vertically center the icons in their flex container */
    
  }
  
  .icon-placeholder {
    width: calc(25% - 40px); /* Calculate the width for 4 icons, subtracting the total gap */
    height: auto; /* Maintain the aspect ratio of the icons */
    margin: 20px; /* This margin creates the gap between the icons */
    /* Other styles remain the same */
  }
}