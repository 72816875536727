

.opportunities-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-image: url("../../../../../assets/themes/USC-1/dark/site-images/gradientbg.png");
  padding: 20px;
  gap: 20px; /* Space between opportunity cards */
  padding-bottom: 10%;
  
  
}

.opportunity-card {
  display: flex;
  flex-direction: column;
  width: 400px; /* Adjust the width as needed */
  max-height: 500px;
  background: #fff; /* Card background color */
  box-shadow: 0 40px 8px rgba(0, 0, 0, 0.1); /* Card shadow for depth */
  border-radius: 8px; /* Rounded corners for the card */
  overflow: hidden; /* Ensures content fits within the card's corners */
}

.opportunity-image img {
  width: 100%; /* Ensure image covers the full width of the card */
  height: 200px; /* Fixed height for consistency */
  object-fit: cover; /* Cover the container without stretching */
}
.opportunity-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Wrap content on smaller screens */
  
}

.opportunity-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Wrap content on smaller screens */
  
}

.opportunity-info {
  flex-basis: 100%; /* Take full width on its own row */
  padding: 10px;
  background: #ffffff; /* Slight background difference for separation */
  border-bottom: 1px solid #eaeaea; /* Line separation from the detailed description */
  font-size: 18px;
  text-transform: uppercase;
  font-weight:500;
}

.opportunity-description {
  flex: 1; /* Take remaining space */
  padding: 10px;
  height: 150px;
  overflow-y: auto;
  background: #ded9d9;
}

/* Responsive adjustments for smaller screens */
@media (max-width: 768px) {
  .archive_section {
    padding-top: 80px; /* Reduced from 130px */
    padding-bottom: 10px; /* Reduced from 20px */
  }

  .page-title, .page-description {
    font-size: 1.5rem; /* Smaller font size for the title */
    margin-top: 10px; /* Reduce margin */
    margin-bottom: 5px; /* Reduce margin */
  }

  .page-description {
    font-size: 1rem; /* Smaller font size for the description */
    max-width: 90%; /* Increase width for better text distribution */
  }
}

@media (max-width: 425px) {
  .archive_section {
    padding-top: 60px; /* Even smaller padding */
    height: 100%;
    
   
  }

  .page-title {
    font-size: 1.25rem; /* Further reduce title font size */
  }
  .opportunities-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    gap: 20px; /* Space between opportunity cards */
    
  }

  .page-description {
    font-size: 0.9rem; /* Further reduce description font size */
  }

  .opportunity-card {
    padding: 10px; /* Adjust padding within cards for more space efficiency */
  }

  .opportunity-info, .opportunity-description {
    font-size: 16px; /* Smaller text within opportunity cards */
  }
}