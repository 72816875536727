@import "../../../theme/index.scss";
.registration_form{
    width: 80%;
    margin: 0 auto;
    font-family: $fontFamily;
    h1{
        color: #ffffff;
    font-family: "Outfit", sans-serif;
    font-size: 46px;
    font-weight: 700;
    font-style: normal;
    text-decoration: none;
    line-height: 1.3em;
    letter-spacing: 0px;
    margin: 20px auto;
    text-align: center;
    }
    .form_section{
        width: 750px;
        margin: 0px auto;
        padding: 20px 0px;
        .form_input {
            width: 100%;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
            border: 1px solid rgba(255, 255, 255, 0.3);
            padding: 12px;
            margin-top: 18px;
            label {
              display: block;
              color: white;
              font-size: 18px;
              padding: 10px 0px;
            }
            input,
            textarea {
              width: 100%;
              padding: 14px;
              border-radius: 28px;
              &:focus {
                outline: none;
                border: none;
              }
            }
            button {
              cursor: pointer;
              border: 0;
              border-radius: 28px;
              font-weight: 600;
              width: 100%;
              font-family: $fontFamily;
              padding: 14px 18px;
              box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
              transition: 0.4s;
              letter-spacing: -0.03px;
              font-size: 18px;
              color: rgb(104, 85, 224);
              background-color: rgba(255, 255, 255, 1);
              border: 1px solid rgba(104, 85, 224, 1);
              &:hover {
                color: white;
                box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
                background-color: rgba(104, 85, 224, 1);
              }
            }
          }
          @media (max-width:768px) {
            width: 100%;
          }
    }
}