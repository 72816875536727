/* Main Archive Section Styling */
.archive-section {
  display: flex;
  background-image: url("../../../../../assets/themes/USC-1/dark/site-images/gradientbg.png");
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding-top: 150px; /* Adjust based on your header height */
  padding-bottom: 50px;
  box-sizing: border-box;
}
/* Title Styling */
.page-title {
  text-align: center;
  color: #FFFFFF; /* Adjust the color to fit your design */
  font-size: 2.5rem; /* Example size, adjust as needed */
  margin-top: 20px;
  margin-bottom: 10px; /* Spacing between title and description */
  font-weight: bold; /* Optional: if you want the title to be bold */
}

/* Description Styling */
.page-description {
  text-align: center;
  color: #CCCCCC; /* Lighter color for the description, adjust as needed */
  font-size: 1.25rem; /* Adjust based on your preference */
  margin-bottom: 20px; /* Spacing between description and the content below */
  max-width: 80%; /* Limit the width of the description text */
  margin-left: auto; /* These two lines center the description block */
  margin-right: auto;
}

.payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Container for the product cards */
.products-container {
 
  display: flex; /* Enable flex layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line if not enough space */
  justify-content: center; /* Center the items horizontally */
  max-width: 100%; /* Ensure the container doesn't overflow the page width */
  gap: 20px; /* Space between the product cards */
  padding-bottom: 10px;
  padding-top: 10px;
}


/* Product Card Styling */
.product-card {
  background: #FFFFFF; /* Light background for the card */
  border-radius: 10px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  margin: 10px; /* Space between cards */
  padding: 20px; /* Padding inside the card */
  max-width: 400px; /* Maximum width of the card */
}

.product-image {
  width: 50%; /* Make the image fill the card width */
  height: auto; /* Keep the image aspect ratio */
  border-radius: 4px; /* Slightly round the corners of the image */
  margin-bottom: 20px; /* Space between the image and the text */
}

.product-info {
  text-align: center; /* Center the text within the product info */
}

.product-title {
  color: #333333; /* Dark color for the title for contrast */
  font-size: 1.25rem; /* Size of the product title */
  margin-bottom: 10px; /* Space between the title and description */
}

.product-price {
  color: #4CAF50; /* A green color for the price to make it stand out */
  font-size: 1.25rem; /* Larger font size for the price */
  font-weight: bold; /* Make the price bold */
  margin-top: 10px; /* Space above the price */
}

.product-description {
  color: #666666; /* Lighter color for the description */
  font-size: 1rem; /* Size of the product description */
  margin-bottom: 20px; /* Space below the description */
}

.product-category {
  color: #666666; /* Lighter color for the description */
  font-size: 1rem; /* Size of the product description */
  margin-bottom: 20px; /* Space below the description */
}
.product-card.selected {
  border: 5px solid green;
}

.checkout-summary {
  width: 100%; /* Make it stretch across the container if not already */
  justify-content: center; /* Center-align items if they should be in a row */
  padding: 20px; /* Adjust padding for visual appeal */
  display: flex; /* Aligns children horizontally */
  align-items: center; /* Vertically centers the children */
  background-color: white; /* Sets the background color to white */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow for some depth */
  border-radius: 10px; /* Rounds the corners */
  margin-top: 20px; /* Adds some space above the checkout summary */
}

.total-price, .items-selected {
  margin-right: 20px; /* Adds some space between the elements */
}

.pay-now-btn {
  margin-left: auto; /* Pushes the button to the right */
  padding: 10px 20px; /* Adds some padding inside the button */
  background-color: #551774; /* Sets a green background color */
  color: white; /* Sets the text color to white */
  border: none; /* Removes the border */
  border-radius: 5px; /* Rounds the corners */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
  font-size: 16px; /* Sets a larger font size for better readability */
}

.pay-now-btn:hover {
  background-color: #0e777d; /* Darkens the button slightly on hover for a visual feedback */
}

.product-website {
  display: inline-block; /* or block depending on layout */
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
  text-decoration: none; /* to remove underline from anchor tag */
  cursor: pointer; /* to show it's clickable */

  &:hover {
    background-color: #0056b3;
  }
}
