@import "../../../../../theme/";

/* Since .levelMemb_section is not used in the JS, we assume .dashboard-page-content is the main container */
.dashboard-page-content {
  background-image: url("../../../../../assets/themes/USC-1/dark/site-images/gradientbg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh; /* Adjusted to min-height to ensure content fits */
  display: flex;
  flex-direction: column; /* Changed to column to stack child elements */
  align-items: center; /* Center content horizontally */
  padding: 20px;
  box-sizing: border-box;
  margin-top: 0px;
}

/* Styling for the close icon */
.close-icon {
  align-self: flex-start; /* Align to the start of the flex container */
  cursor: pointer;
  margin-top: 10px; /* Add some space below the icon */
  padding: 0px;
  color: #ccc;
}

/* Info display styling */
.info-display {
  background-image: url("../../../../../assets/themes/USC-1/dark/site-images/screen_bg.png");
  margin-top: 120px;
  background-color: rgba(243, 243, 243, 0.8); /* Semi-transparent background */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  text-align: left;
  max-width: 500px;
  min-width: 200px;
  width: 27%; /* Adjust width to fit its container */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: add some shadow for depth */
  margin-bottom: 10px;
   /* Space below the info display */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.network-logos {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  gap: 10px; /* Adjust the space between logos */
  width: 25px;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-right: 10px;
  justify-content: flex-end;
}

.network-logo {
  width: 15px; /* Control the size of the logos */
  filter: grayscale(100%); /* Grey out logos by default */
  transition: filter 0.3s ease; /* Smooth transition for greyscale filter */

}

.network-logo.selected {
  filter: none; /* Full color for the selected network logo */
}
.info-item-container {
  display: flex;
  flex-direction: column;
}

/* Info item styling */
.info-item {
  font-size: 22px;
  padding-bottom: 1px;
  line-height: .6; /* Adjust line height for less space between lines of text */

  
}

.info-item-small {
  font-size: 14px; // Set the font size to 10px
  padding-bottom: 12px;
  padding-top: 7px;
  

  

  // If you want to keep the label bold as in the other info items, ensure to apply font-weight to the label specifically
  label {
    font-weight: bold;
  }
}
/* Trading area that contains both left and right button groups */
.trading-area {
  display: flex;
  border-radius: 10px;
  background-color: rgb(84, 29, 10);
  justify-content:space-between;
  width: auto; /* Take up full width of its parent to utilize the space */
  padding: 20px; /* Add some padding around the trading area */
}

/* Left button group styling */
.left-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  border: 2px solid #976303; 
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin-right: 20px; /* Add space between left and right button groups */
}

/* Divider styling */
.divider {
  height: 2px; /* Height of the divider line */
  background-color: #ccc; /* Color of the divider line */
  width: 80%; /* Width of the divider line, relative to its parent */
  margin: 20px auto; 
  align-items: center;
}

/* Right button group styling */
.right-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 20px;
}

/* Input button group styling */
.input-button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px; /* Space between button groups */
  width: 100%; /* Ensure it takes up the full width of its parent */
}

/* Button styling */
.input-button-group button {
  padding: 8px 16px; /* Smaller padding for buttons */
  font-size: 16px; /* Adjust font size as needed */
  margin-top: 5px; /* Space between input and button */
  cursor: pointer; /* Cursor pointer to indicate clickable */
  width: 100%; /* Make buttons take full width of its parent */
  max-width: 200px; /* Limit the width of buttons for better aesthetics */
}

/* Input styling */
.input-button-group input {
  width: 100%; /* Make input take full width of its parent */
  max-width: 200px; /* Limit the width of inputs for better aesthetics */
  padding: 8px; /* Padding inside the input */
  margin-bottom: 5px; /* Space below the input */
}

.network-logo-image {
  max-width: 95px; /* Adjust as needed */
  height: auto; 
  padding-top:20px;
  
 
  
}

@media (max-width: 480px) {
  .dashboard-page-content {
    padding: 15px; /* Slightly more padding for better spacing */
    margin-top: 60px; /* Adjust top margin for smaller headers */
  }

  .close-icon {
    margin-right: auto; /* Align close icon to the right on smaller screens */
  }

  .info-display {
    width: 100%; /* Use full width for better readability */
    padding: 15px; /* Increase padding for more space around content */
    margin-top: 20px; /* Reduce margin-top to save space */
  }

  .trading-area {
    flex-direction: column; /* Stack elements vertically in trading area */
    padding: 15px; /* Increase padding for better spacing */
  }

  .left-buttons, .right-buttons {
    width: 100%; /* Full width for button groups to use space efficiently */
    margin: 10px 0; /* Add vertical margin for spacing between button groups */
  }

  .divider {
    margin: 15px auto; /* Increase margin for better spacing around divider */
  }

  .input-button-group button, .input-button-group input {
    max-width: none; /* Remove max-width restriction for full-width inputs/buttons */
  }

  .network-logo-image {
    max-width: 60px; /* Smaller logo for smaller screens */
  }
}


@media (min-width: 481px) and (max-width: 1024px) {
  .dashboard-page-content {
    padding: 15px; /* Slightly more padding for better spacing */
    margin-top: 60px; /* Adjust top margin for smaller headers */
  }

  .close-icon {
    margin-right: auto; /* Align close icon to the right on smaller screens */
  }

  .info-display {
    width: 100%; /* Use full width for better readability */
    padding: 15px; /* Increase padding for more space around content */
    margin-top: 50px; /* Reduce margin-top to save space */
  }

  .trading-area {
   
    padding: 15px; /* Increase padding for better spacing */
  }

  .left-buttons, .right-buttons {
    width: 100%; /* Full width for button groups to use space efficiently */
    margin: 10px 0; /* Add vertical margin for spacing between button groups */
   
  }

  .divider {
    margin: 15px auto; /* Increase margin for better spacing around divider */
  }

  .input-button-group button, .input-button-group input {
    max-width: none; /* Remove max-width restriction for full-width inputs/buttons */
  }

  .network-logo-image {
    max-width: 60px; /* Smaller logo for smaller screens */
  }
}



@media (min-width: 1025px) {
  .dashboard-page-content {
    padding: 15px; /* Slightly more padding for better spacing */
    margin-top: 60px; /* Adjust top margin for smaller headers */
  }

  .close-icon {
    margin-right: auto; /* Align close icon to the right on smaller screens */
  }

  .info-display {
    width: 100%; /* Use full width for better readability */
    padding: 15px; /* Increase padding for more space around content */
    margin-top: 50px; /* Reduce margin-top to save space */
  }

  .trading-area {
    
    padding: 15px; /* Increase padding for better spacing */
    
  
  }

  .left-buttons, .right-buttons {
    width: 100%; /* Full width for button groups to use space efficiently */
    margin: 10px 0; /* Add vertical margin for spacing between button groups */
  }

  .divider {
    margin: 15px auto; /* Increase margin for better spacing around divider */
  }

  .input-button-group button, .input-button-group input {
    max-width: none; /* Remove max-width restriction for full-width inputs/buttons */
  }

  .network-logo-image {
    max-width: 60px; /* Smaller logo for smaller screens */
  }
}

/* Additional Enhancements */
/* Ensure text elements scale down on smaller screens */
body, button, input {
  font-size: 14px; /* Smaller font size for smaller screens */
}

/* Make sure tap targets are large enough */
button, a {
  min-width: 44px; /* Minimum recommended size for touch targets */
  min-height: 44px;
  padding: 12px; /* Increase padding to make easier to tap */
}