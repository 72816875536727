@import "../../../../theme/index.scss";
.mainDVG_Div {
  width: 100%;
  background-image: url("../../../../assets/themes/USC-1/dark/site-images/bgsecond-1.png");
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-attachment:fixed; /* Optional: Keep the background fixed during scroll */
  
}

.mainSection_about {
  width: 100%;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  .nested_about {
    padding-top: 150px;
    width: 80%;
    font-family: $fontFamily;
    display: flex;
    justify-content: space-between;
    margin: 20px auto 0px;
    .nestedSection {
      width: 60%;

      .heading {
        h2 {
          color: #8eacff;
          font-family: $fontFamily;
          font-size: 28px;
          font-weight: 500;
          font-style: normal;
          text-decoration: none;
          line-height: 4em;
          letter-spacing: 0px;
        }
        h1 {
          color: #ffffff;
          font-size: 60px;
          font-weight: 700;
          font-style: normal;
          text-decoration: none;
          line-height: 1.2em;
          letter-spacing: 0px;
        }
        h5 {
          color: #ffffff;
          font-size: 24px;
          font-weight: 600;
          margin: 0px -5px 0px 0px;
          padding: 0px 0px 35px 0px;
        }
        
      }
      .description {
        p {
          text-align: left;
          color: #ffffff;
          font-family: $fontFamily;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          line-height: 1.7em;
          letter-spacing: 0px;
        }
        ul {
          padding-left: 20px;
          li {
            list-style-type: circle;
            text-align: left;
            color: #ffffff;
            font-family: $fontFamily;
            font-size: 18px;
            font-weight: 500;
            font-style: normal;
            line-height: 1.7em;
            letter-spacing: 0px;
          }
        }
      }
      @media (max-width:995px) {
        .heading{
          h1{
            font-size: 38px;
          }
        }
      }
      @media (max-width:768px) {
        width: 95%;
      }
      @media (max-width:425px) {
        width: 100%;
        .heading{
        h1{
          font-size: 28px;
        }
        h5{
          font-size: 18px;
        }
      }  
    }
    }

    .image-gallery {
      display: flex;
      justify-content: center;
      align-items: flex-start; /* Align items at the top */
      margin-top: 20px;
      flex-wrap: wrap;
      padding: 0 10px; /* Added padding for better spacing on smaller screens */
    }
    
    .gallery-item {
      width: 25%; /* Default width for large screens */
      max-width: 300px; /* Ensure items do not become too large */
      margin: 10px; /* Adjusted margin for better spacing */
      text-align: center; /* Center the content inside the container */
      box-sizing: border-box; /* Include padding and border in the element's total width and height */
    }
    
    
    .gallery-image {
      width: 100%; /* Ensure the image takes up the full width of its container */
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      height: auto; /* Maintain the aspect ratio of the images */
      object-fit: cover; /* Ensure images cover the space without being distorted */
      margin-bottom: 8px; /* Space between the image and the title */
    }
    
    .image-title {
      color: #fff;
      font-size: 16px; /* Ensure text size is legible on all devices */
      margin-bottom: 16px; /* Space after the title before the next container */
    }
    
    @media (max-width: 768px) {
      .gallery-item {
        width: 37%; /* Adjust for medium-sized screens, showing 2 items per row */
        margin: 1.5%; /* Adjust margin to ensure consistent spacing */
      }
    }
    
    @media (max-width: 425px) {
      .gallery-item {
        width: 95%; /* Nearly full width on very small screens, showing 1 item per row */
        margin: 2.5% auto; /* Center items with auto margins and provide vertical spacing */
      }
    }
    

    .nesteSection_two {
      
      width: 30%;
      display: flex; /* Make the container a flex container */
      justify-content: center; /* Center content horizontally */
      align-items: center; /* Center content vertically */
      height: 100%; /* Ensure the container takes up the necessary height */

      .gif_logo {
        img {
          
          max-width: 100%; /* Ensure the image is responsive */
          height: auto; /* Maintain aspect ratio */
          justify-content: center;
        }
      }
      .timer {
        // margin: -107px 0px 0px 0px;
        margin-top: -40px;
        padding: 12px;
        background-color: #ffffff4d;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #ffffff;
        border-radius: 20px 20px 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 14px;
        p {
          font-size: 18px;
        }
      }
      .content_Box {
        background-color: #3002158f;
        border-radius: 20px 20px 20px 20px;
        border-style: solid;
        border-width: 1px 1px 1px 1px;
        border-color: #522100;
        transition: background 0.3s, border 0.3s, border-radius 0.3s,
          box-shadow 0.3s;
        margin-top: 10px;
        margin-bottom: 0px;
        padding: 30px 30px 30px 30px;
        h1 {
          color: #ffffff;
          font-size: 28px;
          font-weight: 700;
          font-style: normal;
          text-decoration: none;
          line-height: 1em;
          letter-spacing: 0px;
          font-family: $fontFamily;

          text-align: center;
        }
        p {
          font-size: 16px;
          // font-weight: 700;
          font-style: normal;
          text-decoration: none;
          // line-height: 1em;
          // letter-spacing: 0px;
          color: #ffffff;
          font-family: $fontFamily;
        }
        .price_eth {
          color: #ffffff;
          font-size: 20px;
          font-weight: 400;
          letter-spacing: 0px;
          font-family: $fontFamily;

        }
        button{
          font-family: $fontFamily;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          text-decoration: none;
          // line-height: 1em;
          // letter-spacing: 0px;
          background-color: #3b2a02;
          border-radius: 20px 20px 20px 20px;
          padding: 14px;
          color: #ffffff;
          width: 160px;
        }

      }


      
      @media (max-width:768px) {
        width: 95%;
        .gif_logo{
          img{
            margin: 0 auto;
          }
        }
      }
      @media (max-width:425px) {
        width: 100%;
      }
    }

   @media (max-width:1024px) {
      width: 90%;  
   }
   @media (max-width:768px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 90%;
    margin: 4px auto 0px;
 }
  }
}

@media (max-width: 425px) {
  .nested_about, .nestedSection {
    margin: 10px 0; /* Adjust margins on smaller screens to reduce whitespace */
    padding: 0 10px; /* Add padding to ensure content doesn't touch the edges */
  }

  .mainSection_about {
    margin-top: 50px; /* Adjust top margin on smaller screens */
  }

  /* Ensure all text and elements within nestedSection are centered and have appropriate size */
  .nestedSection .description p, .nestedSection .description ul li, .nestedSection .heading h2, .nestedSection .heading h5 {
    text-align: center; /* Center text for better readability on small screens */
    font-size: 16px; /* Adjust font size for small screens */
  }
}
