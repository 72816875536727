@import ".../../../../../theme/index.scss";

.promoation_section {
  width: 100%;

  .nested_privacy {
    width: 100%;
    background-image: url("../../../assets/themes/USC-1/dark/site-images/bgsecond-1.png");
    background-size: cover;
    height: auto;

    .child_privacy {
      width: 80%;
      margin: 0 auto;
      margin-top: 70px;

      .logo_privacy {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
        }

        @media (max-width: 425px) {
          // img {
          //   width: 220px;
          // }
        }
      }

      .buybutton {

        padding-top: 20px;
        padding-bottom: 20px;

        .button_info {
          font-style: italic;
          color: red;
          font-size: 16px;
          font-weight: bold;
        }
      
      }

      

      .privacy_content {
        font-family: $fontFamily;
        margin-top: 20px;

        h1, h2, h4 {
          color: #fff;
          font-size: 38px;
          font-weight: 600;
        }

        h2 {
          font-size: 34px;
        }

        p {
          font-size: 19px;
          letter-spacing: 0.6px;
          color: #fff;
        }

        h4 {
          font-size: 25px;
          font-weight: 600;
          padding: 18px 0px;
        }

        ul {
          li {
            padding: 10px 0px;
            color: #fff;
            list-style-type: disclosure-closed;
          }
        }

        @media (max-width: 425px) {
          h1 {
            font-size: 32px;
          }

          h2 {
            font-size: 26px;
          }

          h4 {
            font-size: 22px;
          }
        }
      }

      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
}
