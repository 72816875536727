.menu-bar {
    position: fixed;
    top: 90px; /* Adjust based on your header's height */
    bottom: 50px; /* Adjust based on your footer's height */
    width: 170px; /* Adjust according to your design */
    background-color: #1d1722; /* Dark grey */
    color: #fff; /* For contrast */
    transition: width 0.3s ease; /* Smooth transition for collapsing and expanding */
  }
  
  .menu-bar.collapsed {
    width: 75px; /* Adjust to fit the logo's size in collapsed state */
    
  }
  
  .logo-toggle {
    background: none;
    margin-top: 50px;
    border: none;
    cursor: pointer;
    display: block; /* Ensure the button fills the width of the .menu-bar for easier clicking */
    padding: 10px; /* Adjust as needed */
    width: 100%; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  /* Additional styling as needed */
  }
  
  /* Ensure the SVG logo fits within the button, especially when collapsed */
  .logo-toggle svg {
    width: 100%; /* Adjust as needed */
    height: auto; /* Maintain aspect ratio */
  }
  
  .menu-items {
    list-style-type: none; /* Remove bullet points */
    padding: 0; /* Remove default padding */
    margin-top: 0px; /* Space below the logo button */
  }
  
  .menu-items.hide-items {
    display: none; /* Hide menu items in collapsed state */
  }
  
  .menu-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .menu-item:hover {
    background-color: #444444; /* Slightly lighter grey for hover effect */
  }

  .sub-nav {
    position: absolute;
    left: 360px; /* Position to the right of the menu item */
    top: 125px;
    background: #222; /* Dark background for the sub-nav */
    width: 200px; /* Match or adjust according to the main menu width */
    transition: transform 0.3s ease;
    transform: translateX(-100%);
    /* Additional styling */
  }
  
  .sub-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .sub-nav li {
    padding: 10px;
    display: flex;
    align-items: center;
  }
  
  .sub-nav li img {
    margin-right: 10px;
    width: 30%;
  }
  
  .nft-list {
    position: fixed;
    top: 210px; /* Align with the top of the menu bar */
    left: 410px; /* Adjust based on your menu bar's width */
    width: 250px; /* Width of the NFT sub-menu */
    background: #333; /* Background color of the sub-menu */
    transition: transform 0.3s ease;
    transform: translateX(-100%); /* Initially hide the sub-menu off-screen */
    overflow-y: auto; /* Enable scrolling for long lists of NFTs */
    max-height: calc(100vh - 90px); /* Adjust height based on viewport and header */
  }
  
  .nft-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: #424242; /* Card background color */
    margin: 10px;
    border-radius: 8px; /* Optional: for rounded corners */
  }
  
  .nft-image {
    width: 100px; /* Adjust based on your design */
    height: 100px; /* Adjust based on your design */
    object-fit: cover; /* Ensures images maintain aspect ratio without stretching */
    border-radius: 4px; /* Optional: for rounded image corners */
  }
  
  .nft-title {
    margin-top: 5px;
    color: #fff; /* Text color */
    text-align: center;
  }
  
  /* Show the NFT sub-menu when it's active */
  .nft-list.active {
    transform: translateX(0); /* Slide in the sub-menu */
  }
  