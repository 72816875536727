.archive_section{
    background: url("../../../../assets/themes/USC-1/dark/site-images/gradientbg.png");
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;

}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    margin-top: 0px; /* Adjust this value based on the height of your header */
    padding: 0px;
        /* Other styles */

      
  }
  
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .thumbnail {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  
  .thumbnail video {
    width: 100%;
    height: auto; /* Adjust as needed */
  }
  
  .thumbnail-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 5px;
  }

  .page-title {
    text-align: center;
    color: #fffbfb; /* Choose a color that stands out on your page */
    font-size: 48px; /* Adjust as needed */
    padding-top: 0px;
  }
  