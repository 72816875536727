.container {
  display:inline;
  justify-content: center;
  align-items: center;
  width: 100%;
  
}

.promo_container {
  display: flex;
  flex-direction: row-reverse; /* to make the image appear on the right side */
  @media (max-width:768px) {
    flex-direction: column;

  }
}

.promo_image {
  flex: .8; /* to make the image take up one-third of the available space */
}

.promo_text {
  flex: 2; /* to make the text take up two-thirds of the available space */
  padding: 0 1rem; /* add some padding to the text container for spacing */
  
  
  }

  button {
    width: 80%;
    cursor: pointer;
    border: 0;
    border-radius: 28px;
    font-weight: 600;
    width: 50%;
    padding: 14px 18px;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    letter-spacing: -0.03px;
    font-size: 18px;
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
    &:hover {
      color: white;
      box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
      background-color: rgba(104, 85, 224, 1);
    }
  }


.cards {
  // display: flex;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    // gap: 10px;
  justify-content: center;
  gap: 10px;
  width: 90%;
  margin: 0 auto;
  margin-top: 50px;

}

.card {
  // display: flex;
  // flex-direction: column;
  // align-items: left;
  // justify-content: left;
  width: 100%;
  height: auto;
  background-color: #14092a92;
  // margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 30px;
  color: #fff;
  font-family: sans-serif;
}

.card .card-image {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.card-1 .card-image {
  background-image: url('../../activity/images/jamar.png');
}

.card-2 .card-image {
  background-image: url('../../activity/images/class.png');
}

.card-3 .card-image {
  background-image: url('../../activity/images/dinner.png');
}

.card h2 {
  margin-top: 0;
  font-size: 24px;
}

.card p {
  font-size: 18px;
  line-height: 1.5;
}
