.sidebar{
    width: 280px;
    min-height: 100vh;
    // box-shadow: 0px 4px 8px rgba(0 0 0 / 16%);
    background-color: #000;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 99999;
    transition: 0.5s;
    color: white;
}
.sidebar.active{
    right: 0;
}
.sd-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}
.sidebar-overlay{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
}
.sidebar-overlay.active{
    opacity: 1;
    visibility: visible;
}
.sd-body{
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
}
.sd-body ul{
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
}
.sd-body ul li{
    list-style: none;
    margin-bottom: 8px;
}
.sd-body ul li .sd-link{
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #23222A;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}